<template>
    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: MODULE_PREFIX+'s' }">{{$t('label_'+MODULE_PREFIX+'s')}}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_task')}}</b-breadcrumb-item>
            </div>
        </div>

        <!--actions panel-->
        <b-card no-body>
            <b-card-body class="d-flex flex-wrap">

                <b-button @click="solveRedirect()" variant="outline-primary" class="mr-1">
                    {{$t('label_return_to_tasks_list')}}
                </b-button>
                <b-button @click="$bvModal.show(PREFIX+'-modal')"  variant="outline-success" class="mr-auto">
                    {{$t('label_edit')}}
                </b-button>
                <b-button v-if="task.finished_at" variant="outline-primary" class="mr-1" @click="restartTask(task.id)">
                    {{$t('label_repeat_task')}}
                </b-button>
                <b-button v-else variant="outline-primary" @click="finishAgrmDealTask(task.id)" class="mr-1">
                    {{$t('label_done')}}
                </b-button>
                <b-button variant="outline-danger" class=" " @click="deleteItem(task.id)">{{$t('label_cancel')}}
                </b-button>


            </b-card-body>
        </b-card>


        <b-card>
            <b-card-body class="">
                <b-row>
                    <b-col
                            cols="12"
                            md="6"
                            class=" mb-1"
                    >
                        <b-table-simple class="table-details" responsive no-border-collapse>
                            <b-tbody>
                                <b-tr v-if="task.type == 'agreement'">
                                    <b-td width="40%">{{$t('label_deal_number')}}</b-td>
                                    <b-td class="actions">{{task.agreement.deal_number}}</b-td>
                                </b-tr>
                                <b-tr v-else-if="task.type == 'contract_registry'">
                                    <b-td width="40%">{{$t('label_agreement_register')}}</b-td>
                                    <b-td class="actions">{{task.contract_registry.contract_subject}}</b-td>
                                </b-tr>
                                <b-tr v-if="task.type == 'agreement'">
                                    <b-td width="40%">{{$t('label_client')}}</b-td>
                                    <b-td class="actions">{{task.agreement.first_name + ' ' + task.agreement.last_name}}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="40%">{{$t('label_task_from')}}</b-td>
                                    <b-td class="actions">{{task.user?task.user.name : ''}}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="40%">{{$t('label_deadline')}}</b-td>
                                    <b-td class="actions"> {{formatDate(task.deadline_at, 'HH:mm DD.MM.YYYY', 'unix')}}
                                        <template v-if="task.is_cyclic == 1">
                                            <br>
                                            <span class="badge badge-light-secondary"><feather-icon icon="ClockIcon"/> {{$t(resolveDeadlineText(task.interval_alias))}}</span>
                                        </template>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col
                            cols="12"
                            md="6"
                            class=" mb-1"
                    >
                        <b-table-simple class="table-details" responsive no-border-collapse>
                            <b-tbody>
                                <b-tr>
                                    <b-td width="40%">{{$t('label_task_for_user')}}</b-td>
                                    <b-td class="actions">

                                        <span v-for="ex,ind in task.executors">{{ex.name + ((ind + 1 != task.executors.length)? ', ' : '' )}}</span>

                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="40%">{{$t('label_task_type')}}</b-td>
                                    <b-td class="actions">{{task.type_translation ? $t(task.type_translation)
                                        : (task.type_name? task.type_name: task.subject)}}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td width="40%">{{$t('label_priority')}}</b-td>
                                    <b-td class="actions">{{task.priority ? $t(task.priority.translation_index) : ''}}</b-td>
                                </b-tr>

                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row cols="12">

                    <b-col cols="12">

                        <div class="mt-2 mb-2">
                            <h2 class="pl-1" style=" ">{{$t('label_task_content')}}</h2>
                        </div>


                        <p class="p-1" style="border: 1px solid #f1f1f1;border-radius: 8px; " v-html="task.task"></p>

                        <template v-if="task.documents && task.documents.length > 0">

                            <h5 class="mt-2">{{$t('label_documents')}}</h5>


                            <div class=" doc-modal-wrap" id="task-doc-wrap">

                                <div v-for="doc in task.documents" class="alert-custom-color ">
                                    <a style="white-space: nowrap;"
                                       @click="previewFile($event, 'agreement_tasks_documents/download/', doc)"

                                       target="_blank" class="preview-firstly text-success m-l-15 "
                                       :title=" doc.display_name">
                                        <feather-icon icon="FileIcon" size="16"></feather-icon>
                                        {{doc.display_name}}
                                    </a>
                                </div>

                            </div>
                        </template>
                        <br>

                    </b-col>

                </b-row>

            </b-card-body>
        </b-card>


        <b-card no-body>
            <b-card-body>


                <b-row>

                    <b-col
                            cols="12"
                            md="12"
                            class=" "
                    >

                        <div class="p-2  table-top-toolbar">

                            <b-row>
                                <b-col
                                        cols="12"
                                        md="8"
                                        class="d-flex align-items-center justify-content-start mb-1"
                                ><h2 class="text-center">{{$t('label_comments')}}</h2></b-col>
                                <b-col
                                        cols="12"
                                        md="4"
                                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                                >

                                </b-col>

                                <!-- Search -->

                            </b-row>

                        </div>
                        <!--borderless-->
                        <b-table-simple borderless class="multiple-table" caption-top responsive no-border-collapse>
                            <b-tbody>

                                <b-tr :data-pk="item.id" v-for="item in tableItems" :key="item.id">
                                    <b-td>

                                        <div>

                                            <div class="">
                                                <span class="text-primary">{{item.user.name + (item.user_role?' (' + $t('label_' + item.user_role) + ') ' : '')}}</span>
                                                <span>{{$t('label_posted_on')+': '}}</span> <span class="text-primary">  {{(item.created_at ? formatDate(item.created_at,"DD.MM.YYYY", 'unix') : '--') }}</span>
                                            </div>


                                            <partial-text class="mt-1" style="max-width: 300px"
                                                          :text="item.text"></partial-text>

                                            <template v-if="item.documents && item.documents.length > 0">
                                                <strong class="mt-1 d-inline-block">{{$t('label_documents')}}</strong>
                                                <div v-for="doc in item.documents">

                                                    <div class="comment-docs" role="alert">
                                                        <a style="white-space: nowrap;"
                                                           @click="previewFile($event, 'agreement_task_comments/download/', doc)"
                                                           target="_blank" class="preview-firstly text-success m-l-15"
                                                           :title="doc.display_name">
                                                            <feather-icon icon="FileIcon" size="16"></feather-icon>
                                                            {{doc.display_name}}
                                                        </a>
                                                    </div>

                                                </div>
                                                <div class="divider">
                                                    <hr class="mb-0 mt-2">
                                                </div>
                                            </template>
                                        </div>
                                    </b-td>

                                </b-tr>

                                <b-tr>

                                    <b-td v-if="tableTotal == 0">
                                        {{$t('label_no_comments')}}
                                    </b-td>
                                </b-tr>
                            </b-tbody>

                        </b-table-simple>
                        <!--pagination-->
                        <div class="mx-2 mb-2" v-if="tableTotal > 0">
                            <b-row>

                                <b-col
                                        cols="12"
                                        sm="6"
                                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                                >
                                    <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col
                                        cols="12"
                                        sm="6"
                                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                                >

                                    <b-pagination
                                            size="sm"
                                            v-model="tableData.currentPage"
                                            :total-rows="tableTotal"
                                            :per-page="tableData.perPage"
                                            first-number
                                            last-number
                                            class="mb-0 mt-1 mt-sm-0"
                                            prev-class="prev-item circle"
                                            next-class="next-item circle"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                    icon="ChevronLeftIcon"
                                                    size="12"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                    icon="ChevronRightIcon"
                                                    size="12"
                                            />
                                        </template>
                                    </b-pagination>

                                </b-col>

                            </b-row>
                        </div>

                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>

        <b-card no-body>
            <b-card-body>
                <b-col cols="12" md="6">

                    <h2 class="pl-1  mt-2">{{$t('label_add_new')}}</h2>

                    <validation-observer
                            tag="form"
                            class="p-1"
                            #default="{handleSubmit, reset, invalid}"
                            :ref="PREFIX + '_FORM'"

                    >

                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_content')"
                                rules="required"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_content')"
                            >
                                <b-form-textarea
                                        :placeholder="$t('label_content')"
                                        :state="getValidationState(validationProps)"
                                        v-model="itemData.text"
                                ></b-form-textarea>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                                class="mt-2"
                                #default="validationProps"
                                :name="$t('label_to_ocr')"
                                slim
                        >
                            <b-form-group
                                    label=""
                                    label-for="meeting_type_id"
                            >
                                <!--:checked="itemData.google_sync"-->
                                <b-form-checkbox
                                        id="task-ocr"
                                        v-model="itemData.ocr"

                                >
                                    <label for="task-ocr">{{ $t('label_to_ocr') }}</label>
                                </b-form-checkbox>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                                class="mt-2"
                                :name="$t('label_document')"
                                slim
                                #default="validationProps"
                                rules=""
                        >
                            <file-input

                                    v-model="comment_document"
                                    class="file--uploader drop-block "
                                    :max-files-count='1'
                                    :multiple="false"
                                    :allowed-extensions="false"
                                    :max-file-size='100'
                                    :url="''"
                            >
                                <template #uploadBody>
                                    <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                    <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                    <span class="py-2">{{$t('label_add_file')}}</span>
                                </template>

                                <template #errorBug="fileErrors">

                                    <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>

                                </template>
                            </file-input>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </validation-provider>

                        <div class="d-flex justify-content-end mt-2">

                            <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                                    class="btn btn-primary waves-effect waves-light">{{$t('label_submit')}}
                            </button>
                        </div>

                    </validation-observer>
                    <br>
                </b-col>
            </b-card-body>
        </b-card>


        <process-task-modal :module-item="moduleItem" :is-deal="false" :task-type="task.type" @item-edited="fetchItemData(task.id)" :edited-item="task"></process-task-modal>

    </div>
</template>

<script>
    import processTaskModal from './modals/processTaskModal'
    import partialText from '@/views/components/partialText'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import fileInput from '@/views/components/fileUploadInput'

    import {

        BFormInvalidFeedback,
        BFormCheckbox,
        BFormInput,
        BFormTextarea,
        BTable,
        BDropdownItem,
        BDropdown,
        BPagination,
        BCardBody,
        BCard,
        BButton,
        BTbody,
        BThead,
        BTh,
        BTr,
        BTableSimple,
        BTd,
        BFormGroup

    } from 'bootstrap-vue'

    import {serialize} from 'object-to-formdata';

    import vSelect from 'vue-select'
    import {

        TASK_PREFIX as PREFIX,
        AGREEMENT_PREFIX,
        DEAL_PREFIX,
        resolveDeadlineText
    } from './moduleHelper'

    export default {

        components: {

            BFormTextarea,
            BFormCheckbox,
            BFormInput,
            BFormInvalidFeedback,
            BTable,
            BDropdownItem,
            BDropdown,
            BPagination,
            BCardBody,
            BCard,
            BButton,
            BTbody,
            BThead,
            BTh,
            BTr,
            BTableSimple,
            BTd,
            BFormGroup,
            vSelect,
            processTaskModal,
            partialText,
            ValidationProvider,
            ValidationObserver,
            fileInput
        },

        props: [],

        data() {
            return {

                PREFIX,
                task: {},
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                module_id: 0,
                resolveDeadlineText,

                task_route: '',
                // documents: [],

                is_deal: false,

                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                moduleItem: false,

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },

                blankItemData: {

                    text: '',
                    ocr: [],
                },

                comment_document: [],

                itemData: {},

                prevRoute: ''
            }

        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },
        methods: {

            fetchItemData(id) {
                this.async('get', '/agreement_tasks/' + id, {}, function (res) {

                    this.task = res.data;
                    this.task_route = {
                        name: this.MODULE_PREFIX + '_tabs',
                        params: {alias: this.PREFIX, id: this.task[this.MODULE_PREFIX + '_id']}
                    };
                    if (this.task.type == 'agreement') {
                        this.moduleItem = this.task.agreement;
                    } else if (this.task.type == 'contract_registry'){
                        this.moduleItem = this.task.contract_registry;
                    }
                    this.refreshDataTable();
                }); //
            },

            restartTask(id) {
                this.async('put', '/agreement_' + this.PREFIX + 's/restart/' + this.task.id, {}, function (resp) {
                    this.task.finished_at = null;
                });
            },
            finishAgrmDealTask(id) {
                this.async('put', '/agreement_' + this.PREFIX + 's/finish/' + this.task.id, {}, function (resp) {
                    this.task.finished_at = resp.data.item.finished_at;
                });
            },
            deleteItem(id) {
                let deleteAll = false;
                let allowDeleting = false;
                let self = this;
                this.$swal({
                    title: this.$t('label_deleting'),
                    text: this.$t('label_no_restore') + '!',
                    input: 'checkbox',
                    icon: 'warning',
                    inputValue: 0,
                    inputPlaceholder: this.$t('label_delete_all_documents'),
                    showClass: {
                        popup: 'animate__animated animate__flipInX',
                    },
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: this.$t('label_submit'),
                    cancelButtonText: this.$t('label_cancel'),

                    showLoaderOnConfirm: true,
                    preConfirm(checkboxVal) {
                        deleteAll = checkboxVal;
                        allowDeleting = true;

                    },
                }).then(result => {
                    if (allowDeleting) {
                        self.async('delete', '/agreement_' + self.PREFIX + 's/' + id, {remove_all_docs: deleteAll}, function (resp) {
                            self.$router.replace({name: this.MODULE_PREFIX + 's'});
                        });

                    }

                })

            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.comment_document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {


                let formData = new FormData();
                formData = serialize(this.itemData, {}, formData);
                formData.append('agrm_deal_task_id', this.task.id);
                formData.append('agreement_id', this.task.agreement_id);

                if (this.comment_document.length > 0) {
                    for (let i = 0; i < this.comment_document.length; i++) {
                        formData.append('comment_document[]', this.comment_document[i]);
                    }
                }

                this.async('post', '/agreement_' + this.PREFIX + '_comments', formData, function (resp) {
                    this.resetForm();
                    this.refreshDataTable();
                }, false, {headers: {'Content-Type': 'multipart/form-data'}});

            },
            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + '_comments', {
                    params: {
                        [this.PREFIX + '_id']: this.task.id,
                        agreement_id: this.module_id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            solveRedirect() {

                   let prevRouter = sessionStorage.getItem('previousRouter');
                    let path = '/tasks';
                    if(prevRouter){
                        path = prevRouter.split('//')[1];
                        path = path.split('/').slice(1).join('/');
                        let part = path.split('/')[0];
                        let id = path.substring(path.lastIndexOf('/') + 1);

                       if(part && id.isNumeric()) {
                           switch (part){
                               case 'deal':
                                   this.$router.push({name: 'deal_tabs', params:{alias: 'task', id:id}});
                                   break;
                               case 'agreement':
                                   this.$router.push({name: 'agreement_tabs', params:{alias: 'task', id:id}});
                                   break;
                               default:
                                   this.$router.push({name: 'tasks', params:{}});
                           }
                       } else {
                           this.$router.push({name: 'tasks', params:{}});
                       }
                    } else {
                        this.$router.push({name: 'tasks', params:{}});
                    }

            }
        },

        created() {

            let self = this;
            let item_id = this.$router.currentRoute.params.id;
            this.is_deal = this.$router.currentRoute.path.includes('/' + this.DEAL_PREFIX + '/');
            this.MODULE_PREFIX = this.is_deal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            this.fetchItemData(item_id);

        },


    }
</script>
